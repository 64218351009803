import Vue from 'vue'
import App from './App.vue';
import router from '../router'

Vue.config.productionTip = false
// 适配
import 'amfe-flexible'
// 滚动条
import vuescroll from 'vuescroll';
import 'vuescroll/dist/vuescroll.css';
// 取消浏览器默认行为
document.onselectstart = function () { return false; }

Vue.use(vuescroll);

Vue.prototype.$vuescrollConfig = {
  bar: {
    background: '#d4d4d4'
  },
  scrollPanel: {
    scrollingX: false,

  }
};

new Vue({
  render: h => h(App),
  router,
  beforeCreate() {        
    //这个this指的就是vm!
Vue.prototype.$bus = this
},
}).$mount('#app')
