const En = () => import('../src/components/en')
const Home = () => import('../src/components/Home')
const Game = () => import('../src/components/game.vue')
export default [
    {
        name: 'en',
        path: '/en',
        component: En
    },
    {
        name: 'en',
        path: '/',
        component: En
    },
    {
        name: 'home',
        path: '/cn',
        component: Home
    },
    {
        path: '/game',
        component:Game
    }
]