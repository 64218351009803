import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
//应用路由器插件
Vue.use(VueRouter)

//缓存原来的push、replace
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

//增强push
VueRouter.prototype.push = function (location, okCallback, errCallback) {
    //若程序员使用push的时候，没有传递成功、失败的回调，那就靠catch
    if (okCallback === undefined && errCallback === undefined) {
        return originalPush.call(this, location).catch(() => { })
    } else {
        //若程序员使用push的时候，传递了成功、失败的回调，那就用
        return originalPush.call(this, location, okCallback, errCallback)
    }
}
//增强replace
VueRouter.prototype.replace = function (location, okCallback, errCallback) {
    //若程序员使用push的时候，没有传递成功、失败的回调，那就靠catch
    if (okCallback === undefined && errCallback === undefined) {
        return originalReplace.call(this, location).catch(() => { })
    } else {
        //若程序员使用push的时候，传递了成功、失败的回调，那就用
        return originalReplace.call(this, location, okCallback, errCallback)
    }
}

//创建一个路由器
const router = new VueRouter({
    mode: 'history',
    routes,
})
export default router